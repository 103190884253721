import { Image } from '@finn/ui-components';
import Link from 'next/link';
import React from 'react';

import { BrandSliderItem } from '~/types/slider';

interface IProps {
  item: BrandSliderItem;
}

const MaybeLink: React.FC<{ url: string }> = ({ url, children }) => {
  const trimmedUrl = (url || '').trim();

  // when url was mandatory, we used '#' as a placeholder, so treat # as empty
  if (!trimmedUrl || trimmedUrl === '#') {
    return <>{children}</>;
  }

  const isExternal =
    trimmedUrl?.startsWith('http') && !trimmedUrl?.includes('finn.com');

  return (
    <Link href={trimmedUrl}>
      <a
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noreferrer' : undefined}
      >
        {children}
      </a>
    </Link>
  );
};

const BrandItem: React.FunctionComponent<IProps> = ({ item }) => {
  return (
    <MaybeLink url={item?.url}>
      <div className="!border-pearl hover:!shadow-highlight h-[115px] w-[190px] rounded-sm border border-solid !bg-white">
        <div className="relative mx-6 my-4 h-[80px] w-[135px]">
          <Image
            variant="lazy-loading"
            className="absolute left-1/2 top-1/2 h-auto max-h-full w-20 max-w-full -translate-x-1/2 -translate-y-1/2 transform"
            dataSrc={item?.image}
            alt={item?.key || 'Brand logo'}
          />
        </div>
      </div>
    </MaybeLink>
  );
};

export default BrandItem;
